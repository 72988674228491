@import "styles/component";

.a-card {
    width: 100%;
    max-width: 350px;
    border-radius: 22px;
    overflow: hidden;
    
    &__face {
        @include aspect-ratio(350, 490);
        @include bg-cover;
        @include transition;
        position: relative;

        &:before {
            @include set-overlay;
            content: '';
            background-image: url('../../../assets/images/noise.png');
            opacity: 0.2;
        }
    }

    @include break-at(tablet-midpoint) {
        border-radius: 16px;
    }
    @include break-at(700) {
        border-radius: 14px;
    }
    @include break-at(phablet) {
        border-radius: 12px;
    }
    @include break-at(large-phone) {
        border-radius: 10px;
    }
}