@import "styles/component";

.m-continue-modal {
    text-align: center;

    &__message {
        margin-top: 10px;
        padding-left: 30px;
        padding-right: 30px;
    }

    &__icon {
        margin-bottom: 20px;
    }

    &__heading {
        @include font-family(nunito, black);
        @include font-size(20, 20, -0.5);
        color: $color-darkgray;
    }

    &__body {
        @include font-family(open);
        @include font-size(14, 18);
        @include margin-auto;
        max-width: 175px;
        margin-top: 10px;
        color: $color-gray;
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
    }

    &__button {
        width: 300px;
    }

    &__link {
        @include font-family(open, semibold);
        @include font-size(16);
        @include transition;
        margin-top: 25px;
        color: $color-darkgray;

        &:hover {
            color: $color-darkblue;
        }
    }
}