//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// ✅ Center Position
//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
/**
 * Center Position
 *
 * This mixin centers an element vertically or
 * horizontally through 'absolute' positioning.
 *
 * @param string $dir
 *  Direction to center from
 */
@mixin center-position($dir: top) {
    $valid-dirs: ('top', 'right', 'bottom', 'left');
    @if not(index($valid-dirs, $dir)) {
        @error 'Not a valid value for $dir: #{$dir}'
    }

    $is-y: $dir == 'top' or $dir == 'bottom';
    $is-neg: $dir == 'top' or $dir == 'left';
    $tran-dir: if($is-y, 'Y', 'X');
    $tran-val: if($is-neg, '-50%', '50%');

    position: absolute;
    #{$dir}: 50%;
    transform: translate#{$tran-dir}#{'('}#{$tran-val}#{')'};
}


//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// ✅ Set Behind
//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
/**
 * Set Behind
 *
 * This mixin positions a child element behind the
 * content of the target element.
 *
 * @param string $el
 *  Element to position
 * @param int $z-i
 *  Z-index to assign target element
 */
@mixin set-behind($el, $z-i: 0) {
	@if $z-i > -1 {
        z-index: $z-i;

        #{$el} {
            display: block;
            z-index: -1;
        }
    } @else {
        @error 'Not a valid value for $z-index: #{$z-i}';
    }
}


//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// ✅ Margin Auto
//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
/**
 * Margin Auto
 *
 * This mixin sets an element's left and right
 * margins to 'auto'.
 */
@mixin margin-auto {
    margin-left: auto;
    margin-right: auto;
}


//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// ✅ Set Overlay
//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
/**
 * Set Overlay
 *
 * This mixin is used to expand a child element
 * entirely across its parent.
 */
@mixin set-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// ✅ Clear Fix
//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
/**
 * Clear Fix
 *
 * This mixin fixes the clearing issue for an element
 * using floats.
 *
 * @param string $pseudo
 *  Use "before" or "after" pseudo
 */
@mixin clear-fix($pseudo: after) {
    &:#{$pseudo} {
        content: '';
        display: table;
        clear: both;
    }
}