@import "styles/component";

.p-game {
    @include bg-cover;
    @include set-behind('&:before');
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-image: url('https://cf.geekdo-images.com/e2e0wdO8oVNzD0ZAhzi6MQ__original/img/bxF8YLhe2AdGteLoRMmc6VC_fXI=/0x0/pic3509697.jpg');
    background-position: 65% 50%;

    &:before {
        @include set-overlay;
        content: '';
        background-color: #0C1625;
        opacity: 0.7;
    }

    &__status {
        position: absolute;
        top: 40px;
        right: 45px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
    }

    &__decks {
        display: flex;
    }

    &__deck + &__deck {
        margin-left: 20px;
    }
    
    &__buttons {
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }

    &__button {
        min-width: 150px;

        + .p-game__button {
            margin-left: 20px;
        }
    }

    &__credit {
        @include font-family(open);
        @include font-size(15);
        position: absolute;
        bottom: 10px;
        right: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #8986a7;

        img {
            width: 160px;
            margin-top: 5px;
        }
    }

    @include break-at(phablet) {
        height: auto;
        min-height: 100vh;
        padding-bottom: 90px;
        box-sizing: border-box;

        &__status {
            top: 20px;
            right: 20px;
        }

        &__content {
            flex-grow: 0;
            margin-top: 20px;
        }

        &__decks {
            flex-direction: column;
        }

        &__deck + &__deck {
            margin-top: 10px;
            margin-left: 0;
        }
        
        &__buttons {
            margin-top: 20px;
        }

        &__button + &__button {
            margin-left: 10px;
        }

        &__credit {
            @include margin-auto;
            left: 20px;
        }
    }
}