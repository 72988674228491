$color-white       : #FFFFFF;
$color-black       : #000000;
$color-lightgray   : #D5E3EE;
$color-gray        : #A0BCD8;
$color-darkgray    : #353849;
$color-brightblue  : #19CBFB;
$color-lightblue   : #87C5F9;
$color-blue        : #649CF8;
$color-darkblue    : #488AF9;
$color-brightgreen : #A8DF67;
$color-green       : #94D56F;
$color-darkgreen   : #66BB46;
$color-brightpurple: lighten(#583689, 25);
$color-purple      : #583689;
$color-darkpurple  : #583689;
$color-brightorange: #F6C717;
$color-darkorange  : #CF772C;
