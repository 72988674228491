//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// ✅ Break At Point
//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
/**
 * Break At Point
 *
 * Wrapper mixin to define responsive CSS at given point.
 * (Requires breakpoints map under $breakpoints variable to use namespaced values)
 *
 * @param map-key/number $breakpoint
 *  Breakpoint map key (i.e. large-phone) or breakpoint pixel (i.e. 1200)
 * @param string $relativity
 *  Max or min relativity for breakpoint (default: max)
 */
@mixin break-at($breakpoint, $relativity: max) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media screen and (#{$relativity}-width: map-get($breakpoints, $breakpoint)) {
			@content;
		}
	}
	@else{
		@media screen and (#{$relativity}-width:$breakpoint * 1px) {
			@content;
		}
	}
}


//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// ✅ Break Between Points
//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
/**
 * Break Between Points
 *
 * Wrapper mixin to define responsive CSS between (2) given points.
 * (Requires breakpoints map under $breakpoints variable to use namespaced values)
 *
 * @param map-key/number $min-breakpoint
 *  Minimum breakpoint map key (i.e. small-phone) or breakpoint pixel (i.e. 320)
 * @param map-key/number $max-breakpoint
 *  Maximum breakpoint map key (i.e. computer) or breakpoint pixel (i.e. 1200)
 */
@mixin break-between($min-breakpoint, $max-breakpoint) {
	@if map-has-key($breakpoints, $min-breakpoint) {
		$min-breakpoint:map-get($breakpoints, $min-breakpoint);
	}
	@else{
		$min-breakpoint:$min-breakpoint * 1px;
	}

	@if map-has-key($breakpoints, $max-breakpoint) {
		$max-breakpoint:map-get($breakpoints, $max-breakpoint);
	}
	@else{
		$max-breakpoint:$max-breakpoint * 1px;
	}

	@media screen and (min-width: $min-breakpoint) and (max-width: $max-breakpoint) {
		@content;
	}
}


//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// ✅ Set Container Padding
//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
/**
 * Set Container Padding
 *
 * Mixin to force padding around element at mobile points.
 *
 * @param number $max-width
 *  Max width of container element
 * @param number $padding
 *  Padding for left and right side
 */
@mixin container-padding($max-width, $padding) {
    max-width: $max-width + ($padding * 2);
    padding-left: $padding;
    padding-right: $padding;
    box-sizing: border-box;
}