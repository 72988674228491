@import "styles/component";

.m-deck {
    width: 370px;
    
    &__slot {
        @include aspect-ratio(370, 510);
        position: relative;
        border-radius: 32px;
        background: linear-gradient(0, rgba($color-white, 0.02) 30%, rgba($color-white, 0.12) 100%);
        backdrop-filter: blur(5px);
        overflow: hidden;
        box-shadow:
            0 -5px 10px 2px rgba($color-white, 0.2) inset,
            0 10px 15px rgba(#000, 0.6);
        
        &:before {
            @include set-overlay;
            content: '';
            background-image: url('../../../assets/images/noise.png');
            opacity: 0.15;
        }
    }

    &__card,
    &__text {
        position: absolute;
    }

    &__card {
        top: 2%;
        left: 50%;
        width: 94%;
        transform: translateX(-50%);
        box-shadow: 0 5px 8px rgba(#000, 0.2);
    }

    &__text {
        @include font-family(open, bold);
        @include font-size(30);
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        background-color: #000;
        color: transparent;
        text-transform: uppercase;
        text-shadow: 1px 1px 1px rgba($color-white,0.4);
        text-align: center;
        background-clip: text;
        opacity: 0.35;
    }

    @include break-at(tablet-midpoint) {
        width: 300px;

        &__slot {
            border-radius: 25px;
        }

        &__text {
            font-size: em(25);
        }
    }
    @include break-at(700) {
        width: 250px;

        &__slot {
            border-radius: 20px;
        }

        &__text {
            font-size: em(22);
        }
    }
    @include break-at(phablet) {
        width: 220px;

        &__slot {
            border-radius: 16px;
        }
    }
    @include break-at(large-phone) {
        width: 182px;

        &__slot {
            border-radius: 14px;
        }
    }
}