@import "styles/component";

.a-button {
    @include font-family(open, extrabold);
    @include font-size(14, 14, 2);
    @include set-behind('&:before');
    @include transition;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding-top: 1px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    border-radius: 12px;
    background: linear-gradient(50deg, $color-brightpurple 0%, $color-darkpurple 65%);
    color: $color-white;
    text-align: center;
    text-transform: uppercase;
    box-shadow:
        0 4px $color-darkpurple,
        0 7px 10px rgba(darken($color-darkpurple, 20), 0.6),
        0 -2px 6px rgba(lighten($color-brightpurple, 20), 0.4) inset;

    &:active {
        transform: translateY(5px);
    }

    &:hover {
        box-shadow:
            0 4px $color-darkpurple,
            0 4px 5px rgba(darken($color-darkpurple, 30), 0.9),
            0 -5px 8px rgba(lighten($color-brightpurple, 20), 0.5) inset;
    }

    &--green {
        background: linear-gradient(50deg, $color-brightgreen 0%, $color-darkgreen 65%);
        box-shadow:
            0 4px $color-darkgreen,
            0 7px 10px rgba(darken($color-darkgreen, 20), 0.6),
            0 -2px 6px rgba(lighten($color-brightgreen, 20), 0.4) inset;

        &:hover {
            box-shadow:
                0 4px $color-darkgreen,
                0 4px 5px rgba(darken($color-darkgreen, 30), 0.9),
                0 -5px 8px rgba(lighten($color-brightgreen, 20), 0.5) inset;
        }
    }

    &--blue {
        background: linear-gradient(50deg, $color-brightblue 0%, $color-darkblue 80%);
        box-shadow:
            0 4px $color-darkblue,
            0 7px 10px rgba(darken($color-darkblue, 20), 0.6),
            0 -3px 7px 2px rgba(lighten($color-brightblue, 20), 0.5) inset;

        &:hover {
            box-shadow:
                0 4px $color-darkblue,
                0 4px 5px rgba(darken($color-darkblue, 30), 0.9),
                0 -3px 7px 2px rgba(lighten($color-brightblue, 20), 0.8) inset;
        }
    }

    &--orange {
        background: linear-gradient(50deg, $color-brightorange 0%, $color-darkorange 65%);
        box-shadow:
            0 4px $color-darkorange,
            0 7px 10px rgba(darken($color-darkorange, 20), 0.6),
            0 -2px 6px rgba(lighten($color-brightorange, 20), 0.4) inset;

        &:hover {
            box-shadow:
                0 4px $color-darkorange,
                0 4px 5px rgba(darken($color-darkorange, 30), 0.9),
                0 -5px 8px rgba(lighten($color-brightorange, 20), 0.5) inset;
        }
    }

    &--white {
        background: $color-white;
        color: $color-blue;
        box-shadow:
            0 4px $color-blue,
            0 7px 10px rgba(#000, 0.2),
            0 -2px 2px 2px $color-lightgray inset;

        &:hover {
            box-shadow:
                0 4px $color-blue,
                0 4px 5px rgba(#000, 0.4),
                0 -2px 2px 2px $color-lightgray inset;
        }
    }
}