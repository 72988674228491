@import "styles/component";

.a-era {
    width: 35px;
    height: 35px;
    padding: 2px;
    box-sizing: border-box;
    border-radius: 50%;
    background-color: $color-white;
    box-shadow: 0 5px 10px rgba(#000, 0.6);

    &__status {
        @include transition(transform, 0.3s);
        position: relative;
        width: 50%;
        height: 100%;
        transform-origin: 100% 50%;
        overflow: hidden;

        &:before {
            @include transition(background-color, 0.3s);
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 200%;
            border-radius: 50%;
            background-color: #4A89A8;
        }
    }

    &--rail {
        .a-era {
            &__status {
                transform: rotate(180deg);

                &:before {
                    background-color: $color-black;
                }
            }
        }
    }
}