@import "styles/component";

.m-modal {
    @include transition(z-index, 0s 0.2s);
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    visibility: hidden;

    &__overlay,
    &__wrapper {
        @include transition;
        opacity: 0;
    }

    &__overlay {
        @include set-overlay;
        backdrop-filter: blur(10px);

        &:before {
            @include set-overlay;
            content: '';
            background-image: url('../../../assets/images/noise.png');
            opacity: 0.15;
        }
    }

    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    &__wrapper {
        position: relative;
        z-index: 1;
        padding: 30px 20px;
        background-color: $color-white;
        border-radius: 20px;
        transform: scale(0.8);
        box-shadow:
            0 6px 0 $color-gray,
            0 -4px 8px 2px $color-lightgray inset,
            0 10px 15px rgba(#000, 0.6);
    }

    &--open {
        @include transition(none);
        z-index: 20;
        visibility: visible;

        .m-modal {
            &__overlay,
            &__wrapper {
                opacity: 1;
            }

            &__wrapper {
                transform: scale(1);
            }
        }
    }
}