@mixin font-family($family, $weight: regular, $style: default) {
	$font-settings: '';
	$is-hosted-font: false;

	@if variable-exists(web-fonts) and map-has-key($web-fonts, $family) {
		$font-settings: map-get($web-fonts, $family);
	}
	@else if variable-exists(hosted-fonts) and map-has-key($hosted-fonts, $family) {
		$font-settings: map-get($hosted-fonts, $family);
		$is-hosted-font: true;
	}
	@else {
		@error "Font family not found in font sass maps..."
	}

	$font-family: map-get($font-settings, family);
    $font-weight: map-get(map-get($font-settings, weights), $weight);

    @if $is-hosted-font {
        font-family: $family + '-' + $weight;
    }
    @else {
        font-family: #{$font-family};
        font-weight: $font-weight;
    }

	@if $style != default {
		font-style: italic;
	}
}


//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// ✅ Font Size
//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
/**
 * Font Size
 *
 * This mixin sets the font size.
 *
 * @param number $font-size
 *  Font size in pixels w/o unit
 * @param number $line-height
 *  Font line-height in pixels w/o unit
 * @param number $letter-spacing
 *  Font letter-spacing in pixels w/o unit
 */
@mixin font-size($font-size, $line-height: null, $letter-spacing: null) {
	@if (str-index('#{$font-size}', 'px')) {
		font-size: #{$font-size};
	}
	@else {
		font-size: em($font-size);
	}

	@if $line-height != null {
		@if (str-index('#{$line-height}', 'px')) {
			line-height: #{$line-height};
		}
		@else {
			line-height: em($line-height, $font-size);
		}
	}

    @if $letter-spacing != null {
        @if (str-index('#{$letter-spacing}', 'px')) {
			letter-spacing: #{$letter-spacing};
		}
		@else if (str-index('#{$letter-spacing}', 'em')) {
			letter-spacing: #{$letter-spacing};
		}
		@else {
			letter-spacing: $letter-spacing * 1px;
		}
    }
}


//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// ✅ Placeholder Color
//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
/**
 * Placeholder Color
 *
 * This mixin sets the color for an input's placeholder.
 *
 * @param string $color
 *  Hex or RGB value for text color
 */
@mixin placeholder-color($color) {
	&::-webkit-input-placeholder {
	    color: $color;
	}

	&:-moz-placeholder {
	    color: $color;
	    opacity: 1;
	}

	&::-moz-placeholder {
	    color: $color;
	    opacity: 1;
	}

	&:-ms-input-placeholder {
	    color: $color;
	}
}


//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// ✅ Font Import
//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
/**
 * Font Import
 *
 * This mixin sets up self-hosted fonts.
 *
 * @param string $family
 *  Name of font family
 * @param string $folder
 *  Name of folder that font is in
 * @param string $file
 *  Name of file w/o extension name
 */
 @mixin font-import($family, $folder, $file, $types: null) {
    font-family: $family;
    
    $srclist: ();

    @if $types {
        @each $type in $types {
            $url: '';

            @if $type != 'eot' {
                $url: "url('../fonts/#{$folder}/#{$file}.#{$type}') format('#{$type}')";
            }
            @else {
                $url: "url('../fonts/#{$folder}/#{$file}.eot?#iefix') format('embedded-opentype')";
            }

            $srclist: append($srclist, #{$url}, comma);
        }

        @if index($types, 'eot') != null {
            src: url('../fonts/#{$folder}/#{$file}.eot');
        }
        
        src: $srclist;
    }
    @else {
        src: url('../fonts/#{$folder}/#{$file}.eot');
        src: url('../fonts/#{$folder}/#{$file}.eot?#iefix') format('embedded-opentype'),
            url('../fonts/#{$folder}/#{$file}.woff2') format('woff2'),
            url('../fonts/#{$folder}/#{$file}.woff') format('woff'),
            url('../fonts/#{$folder}/#{$file}.tff') format('truetype');
    }
}