//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// ✅ Aspect Ratio
//≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
/**
 * Aspect Ratio
 *
 * This mixin sets an aspect ratio size for an element.
 *
 * @param number $width
 *  Width of ratio reference
 * @param number $height
 *  Height of ratio reference
 * @param number $container
 *  Width of element's container
 */
@mixin aspect-ratio($width: 1, $height: 1, $container: $width) {
    $percentage: (($width / $container) * 100) * 1%;
    width: $percentage;
    padding-top: ($height / $width) * $percentage;
}